.real-taste-of-india-sidebar {
  font-family: "Poppins", sans-serif;
  .side-by-side {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .todays-special {
    h1 {
      font-size: 25px;
      color: #ffffff;
      font-weight: 500;
      background-color: #21920f;
      text-align: center;
      padding: 10px 0;
      border-radius: 15px 15px 0 0;
      margin-bottom: 0;
    }
    .special-items {
      background-color: #f3f3f3;
      padding: 20px;
      border-radius: 0 0 15px 15px;
      .special-item {
        padding: 10px;
        background-color: #ffffff;
        margin-bottom: 30px;
        img {
          width: 100%;
          height: 216px;
          object-fit: cover;
        }
        .item-name {
          font-size: 18px;
          color: #242424;
          font-weight: 500;
          margin: 10px 0;
        }
        .price,
        .add-to-cart {
          font-size: 25px;
          color: #20920f;
          font-weight: 600;
        }
        .add-to-cart {
          font-size: 18px;
        }
      }
    }
  }
  .order-info {
    margin-top: 40px;
    background-color: #f3f3f3;
    border-radius: 15px;
    padding: 20px;
    p {
      margin-bottom: 12px;
      font-size: 18px;
      color: #242424;
      font-weight: 400;
      &:last-of-type {
        margin-bottom: 0;
      }
      svg {
        color: #20920f;
        font-size: 18px;
        margin-right: 10px;
      }
      a {
        color: #242424;
        position: relative;
        line-height: 18px;
        &:first-of-type {
          padding-right: 7px;
          margin-right: 7px;
          border-right: 2px solid #242424;
        }
      }
    }
  }
  .accepted-payment {
    margin: 30px 0;
    h1 {
      font-size: 29px;
      color: #242424;
      font-weight: 400;
    }
    img {
      height: 50px;
      object-fit: contain;
    }
  }
}
