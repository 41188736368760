// @import "variable";
// .contact-us {
//   padding: 0 1rem 1rem 1rem;
//   .title {
//     // font-size: 1.5rem;
//     font-size: 17px;
//     margin: 2rem 0;
//   }
//   form {
//     .form-group {
//       margin-bottom: 0;
//     }
//     .submit-btn {
//       background-color: $primary;
//       border-radius: 0;
//       margin-top: 0rem;
//     }
//   }
//   li {
//     list-style: none;
//   }
//   @media (max-width: 1199px) and (min-width: 992px) {
//     .contact-details {
//       text-align: right;
//     }
//   }
//   @media (max-width: 575px) {
//     .contact-details {
//       margin-top: 2rem;
//       text-align: center;
//     }
//   }
// }
// .messageSubmitButton {
//   background-color: #21920f;
//   cursor: pointer;
//   span {
//     color: white;
//     font-size: 18px;
//   }
// }

@import "variable";

.contactUsHeading {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  color: $primary;
  letter-spacing: 1px;
  line-height: 1.7;
  word-spacing: 1px;
  text-align: justify;
}

.contactDescSpan {
  font-size: 18px;
  // line-height: 1.5;
  // letter-spacing: 1.5px;
  letter-spacing: 1px;
  line-height: 1.7;
  word-spacing: 1px;
  text-align: justify;
}

.selectBoxDivContactUs {
  background-color: $offerBox;
}
.contactUsSubmitButton {
  background-color: $primary;
  color: white;
  outline: none;
  border-width: 0ch;
  font-size: 18px;
}
