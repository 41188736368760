@import "variable";

.brandList {
  margin-top: 40px;
}
.brandCard {
  .cardImage {
    width: 100%;
    background-color: $buyBg;
    display: flex;
    justify-content: center;
    img {
      width: auto;
      height: auto;
      max-height: 150px;
    }
  }
  .brandTitle {
    font-size: 20px;
    font-weight: 600;
  }
  .brandText {
    font-size: 16px;
    color: $secondary;
  }
}
