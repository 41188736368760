.about-us {
  padding: 1rem;
  .title {
    // font-size: 1.5rem;
    font-size: 17px;

    margin: 2rem 0;
  }
  .desc {
    letter-spacing: 1px;
    // font-size: 1.1rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7;
    word-spacing: 2px;
    text-align: justify;
  }
  .story-desc {
    margin-top: 2rem;
    h6 {
      font-size: 34px;
      font-weight: 600;
      margin-bottom: 7px;
    }
    p {
      font-size: 20px;
      margin-bottom: 0px;
      font-weight: 500;
    }
    @media (max-width: 767px) {
      margin-top: 1rem;
    }
  }
  #store2 {
    text-align: right;
  }
  .imgDivOfStore {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    img {
      width: 48%;
      height: 300px;
      object-fit: cover;
      margin: 10px 0;
      @media (max-width: 767px) {
        width: 49%;
      }
      &:nth-child(3) {
        width: 100%;
        @media (max-width: 767px) {
        }
      }
    }
  }
  @media (max-width: 767px) {
    #store1,
    #store2,
    #store3 {
      text-align: center;
    }
  }
  .staff-img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    @media (max-width: 1199px) {
      background-size: contain;
    }
  }
}
@media only screen and (max-width: 767px) {
  .imgDivOfStore {
    order: 1;
  }
}
