@import "variable";
.delivery-address-table {
  h4 {
    color: $textColor;
    text-transform: uppercase;
    .borderBottom {
      border-bottom: 1px solid $textColor;
      padding-bottom: 1rem;
      // margin-bottom: 2rem;
      width: 5%;
    }
  }
  .editColorSpan {
    color: $primary;
  }
  .deleteColorSpan {
    color: $danger;
  }
  .deliveryAddressHeading {
    //   background-color: red;
    //   margin-bottom: 20px;
    text-transform: uppercase;
  }
  .addNewAddressCustomBtn {
    width: 80%;
    height: 40px;
    border: 2px $primary solid;
    background-color: $buyBg;
    color: $primary;
    cursor: pointer;
    button {
      border-width: 0ch;
      outline: none;
    }
  }
  .spanItem {
    cursor: pointer;
  }

}
