// source sans pro
//font-family: 'Source Sans Pro', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
// exo2
@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
// montserrat
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./include/variable";
@import "./include/header";
body {
  font-family: "Exo 2", "Source Sans Pro", "Montserret", sans-serif;
}
.row {
  margin: 0 0px;
}
.card-title {
  font-size: 18px;
}

.green-button {
  width: 150px;
  height: 50px;
  border-radius: 20px;
  background-color: green;
  color: white;
  cursor: pointer;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

[id^="gb-widget"] {
  right: 5px !important;
  bottom: 5px !important;
}

.videoWrapperHomePage {
  height: 600px;
  position: relative;
  video {
    height: 500px;
    width: 90%;
    height: 90%;
    position: absolute;
    object-fit: cover;
  }
  .playBtn {
    position: absolute;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    height: 90%;
    width: 90%;
    .contentDiv {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-bottom: 30px;
      margin-left: 30px;
      font-size: 30px;
      color: white;
      font-weight: bold;
    }
  }
}

.bikajiVideoModel {
  background-color: transparent;
  .modal-content {
    background-color: transparent;
    border: 0ch;
    outline: none;
    .closeButton {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      margin-right: 40px;
      background-color: white;
      border-radius: 50px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }
  }
  video {
    width: 100%;
    height: 100%;
  }
}
