.testimonial-card {
  text-align: left;
  border-radius: 8px;

  margin: 0 auto;
  margin-bottom: 60px;

  .testimonial-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;

    margin-top: 47px;

    .testimonial-description {
      max-width: 890px;
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      color: #231f20;
    }

    .testimonial-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      text-transform: uppercase;
      color: #212121;
    }

    .testimonial-designation::before {
      content: "- ";
    }

    .testimonial-designation {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #8c8c8c;
    }

    .testimonial-images {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 14px;
    }

    .testimonial-images img {
      // width: 150px;
      // height: 150px;
      // object-fit: cover;
      max-width: 100%;

      &:hover {
        box-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
      }
    }

    .imageVideoContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .testimonial-video iframe {
      max-width: 240px !important;
      max-height: 150px !important;

      aspect-ratio: 16/9;
    }

    iframe {
      min-width: 240px !important;
      min-height: 150px !important;
    }

    // .testimonial-card.large-content {
    //   /* Adjust card size for large content */
    //   min-height: 400px;
    // }

    // .testimonial-images.large-size {
    //   /* Adjust image size for large content */
    //   img.testimonial-image {
    //     width: 300px;
    //     height: 300px;
    //     object-fit: cover;
    //     margin-right: 20px;
    //     margin-bottom: 10px;
    //   }
    // }

    .testimonial-video.large-size iframe {
      /* Adjust video size for large content */
      max-width: 480px !important;
      min-height: 300px !important;

      @media (max-width: 768px) {
        max-width: 300px !important;
        min-height: 200px !important;
      }
    }
  }
}
.swiper-button-prev,
.swiper-button-next {
  top: auto !important;
  bottom: 0 !important;
  margin-top: 0 !important; 
}
// slider
.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 16px !important;
  text-transform: none !important;
  font-variant: initial;
  background: #20920e !important;
  padding: 13px 17px !important;
  border-radius: 50%;
  color: white !important;

  filter: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);

  // position: absolute !important;
  // top: 490% !important;

  // media query for tablet
  // @media (max-width: 991.9px) {
  //   top: 660% !important;
  // }

  // // media query for mobile
  // @media (max-width: 768px) {
  //   top: 700% !important;
  // }

  // // media query for mobile
  // @media (max-width: 460px) {
  //   top: 1100% !important;
  // }
}

.swiper-button-prev:after {
  background: #ffffff !important;
  color: #bcbcbc !important;
}

.swiper-button-prev {
  left: 30% !important;
}

.swiper-button-next {
  right: 30% !important;
}

.swiper-pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
  color: #8c8c8c;
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  position: absolute !important;
  bottom: 10px !important;
  z-index: -10 !important;
}

.swiper-slide.col-12.swiper-slide-active {
  min-height: 550px !important;

  // media query for tablet
  @media (max-width: 991.9px) {
    min-height: 680px !important;
  }

  // media query for mobile
  @media (max-width: 768px) {
    min-height: 720px !important;
  }

  // media query for mobile
  @media (max-width: 460px) {
    min-height: 1070px !important;
  }
}

// .video_image {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 14px;

//   overflow: hidden;
// }

// .testimonial-video-container,
// .testimonial-video {
//   max-width: 260px !important;
//   max-height: 200px !important;
// }

// .testimonial-video-container iframe {
//   width: 260px !important;
//   height: 200px !important;
// }
