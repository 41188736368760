@import "variable";
form {
  .discount-submit {
    margin-left: 1rem;
  }
}
.checkout-details {
  h4 {
    color: $textColor;
    text-transform: uppercase;
    .borderBottom {
      border-bottom: 1px solid $textColor;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      width: 5%;
    }
  }
  .bookingSumPrice {
    span {
      @media (max-width: 374px) {
        display: none;
      }
    }
  }
  .detail-form {
    margin-top: 2rem;
    label {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 600;
      color: $textColor;
    }
    input {
      background-color: transparent;
    }
    .form-submit {
      text-align: center;
      margin-top: 2rem;
      button {
        font-size: 1rem;
        padding: 1rem;
        color: #1e421a;
        text-transform: uppercase;
        border: 2px solid #1e421a;
        background-color: transparent;
      }
    }
  }
  .checkout-content {
    .detail-form {
      span {
        font-size: 2.5rem;
      }
      label {
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 600;
        color: $textColor;
        margin: 1rem 0;
      }
      .form-submit {
        text-align: center;
        margin-top: 2rem;
        .submit-button {
          font-size: 1rem;
          padding: 1rem;
          color: #1e421a;
          text-transform: uppercase;
          border: 2px solid #1e421a;
          background-color: transparent;
        }
      }
    }
    h5 {
      font-size: 1.2rem;
      font-weight: 600;
      color: $textColor;
      margin-bottom: 1rem;
      span {
        position: absolute;
        right: 1rem;
        color: #1e421a;
      }
    }
    p {
      text-align: justify;
      letter-spacing: 1px;
      margin-bottom: 1rem;
    }
    h6 {
      margin: 1.8rem 0;
      color: $textColor;
      font-weight: 600;
    }
    .form-submit {
      text-align: center;
      margin-top: 2rem;
      button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
        color: #1e421a;
        text-transform: uppercase;
        border: 2px solid #1e421a;
        background-color: transparent;
      }
      a {
        font-size: 1rem;
        padding: 0.5rem 1rem;
        color: #1e421a;
        text-transform: uppercase;
        border: 2px solid #1e421a;
        background-color: transparent;
      }
      .pay-btn {
        padding: 0;
        border: none !important;
        text-align: center;
        cursor: pointer;
        img {
          max-width: 100%;
          height: auto;
        }
        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
  }

  .paymeProceedButton {
    min-width: 200px;
    min-height: 45px;
    // width: 30%;
    color: white;
    font-weight: 600;
    // background-color: #dc3a37;
    // font-size: 1.3rem;
    border: none;
    margin-top: 2rem;
  }
  .onDeliveryProceedButton {
    min-width: 200px;
    min-height: 45px;
    // width: 30%;
    color: white;
    font-weight: 600;
    // background-color: $secondary;
    // font-size: 1.3rem;
    border: none;
    margin-top: 2rem;
  }
  //payme
  .tab-content {
    .payme-section {
      overflow: hidden;
      margin-bottom: 2rem;
      img {
        -webkit-animation-name: opacity;
        animation-name: opacity;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        object-fit: cover;
        object-position: center;
        height: 400px;
        width: 400px;
        @media (max-width: 991px) {
          height: 250px;
        }
      }
      .pay-desc {
        text-align: center !important;
        color: #362415;
        font-weight: 600;
        img {
          width: 140px;
          height: 100px;
          margin-top: -40px;
          margin-bottom: -20px;
        }
      }
      .payme-steps {
        list-style: none;
        text-align: justify;
        line-height: 2;
        padding: 0;
        .payment-steps {
          font-size: 1.5rem;
          font-weight: 600;
        }
        .paymeStep {
          font-size: 1rem;
          .numberCircle {
            border-radius: 50%;
            padding: 2px 8px;
            border: 2px solid #666;
            color: #fff;
            background: #666;
            margin-right: 10px;
          }
          .one {
            &:before {
              content: "1";
            }
          }
          .two {
            &:before {
              content: "2";
            }
          }
          .three {
            &:before {
              content: "3";
            }
          }
        }
      }
    }
  }
}
