@import "variable";

.BoxDiv1 {
  background-color: $dealsBg1;
  border-radius: 20px;
  overflow: hidden;
}
.BoxDiv2 {
  background-color: $dealsBg1;
  border-radius: 20px;
  overflow: hidden;
}
.BoxDiv3 {
  background-color: $featuredBg;
  border-radius: 20px;
  overflow: hidden;
}
.BoxDiv4 {
  background-color: $featuredBg;
  border-radius: 20px;
  overflow: hidden;
}
.serviceBtn {
  background-color: white;
  color: black;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  border-width: 0ch;
}
.serviceBoldText {
  font-size: 20px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .serviceHeading {
    font-size: 14px;
  }
  .serviceBoldText {
    font-size: 14px;
  }
  .serviceBtn {
    font-size: 14px;
  }
}
