@import "variable";

.titleHS {
  font-size: 30px;
  font-weight: 600;
}

.advTypeHeaderMainDiv {
  width: 100%;
  height: auto;
  background-color: $buyBg;
  border-radius: 20px;
  font-size: 17px;
  .advButton {
    width: 140px;
    height: 100%;
    margin-right: 5px;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    text-align: center;
  }
  .advButton:hover {
    background-color: $danger;
    color: white;
  }
}
