@import "variable";
.deal-section {
  padding: 0 1.5rem;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    #block1 {
      background-color: $dealsBg1;
    }
    #block2 {
      background-color: $dealsBg2;
    }
    #block3 {
      background-color: $dealsBg3;
    }
    #block4 {
      background-color: $dealsBg4;
    }
    .brand-block {
      height: 300px;
      border-radius: 10px;
      .text-section {
        position: relative;
        text-align: left;
        top: 3.5rem;
        z-index: 9;
        left: 1rem;
        h2 {
          font-weight: 600;
          color: #000;
          font-size: 24px;
          @media (max-width: 376px) {
            max-width: 90%;
          }
          @media (max-width: 374px) {
            font-size: 1.25rem;
          }
        }
        p {
          max-width: 60%;
          font-size: 16px;
          color: #000;
          @media (max-width: 1199px) {
            max-width: 60%;
          }
          @media (max-width: 635px) {
            max-width: 50%;
            font-weight: 800;
          }
          @media (max-width: 575px) {
            max-width: 80%;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .btn {
          text-align: center;
          width: 32%;
          border-radius: 50px;
          font-weight: 600;
          @media (max-width: 1199px) {
            width: 50%;
          }
          @media (max-width: 455px) {
            width: 40%;
          }
          @media (max-width: 364px) {
            width: 50%;
          }
        }
      }
      .img-section {
        position: relative;
        margin-top: 7rem;
        @media (max-width: 767px) and (min-width: 636px) {
          margin-top: 1.5rem;
        }
        @media (max-width: 635px) and (min-width: 534px) {
          margin-top: 3rem;
          img {
            width: 225px;
            max-height: 225px !important;
          }
        }
        @media (max-width: 533px) and (min-width: 365px) {
          margin-top: 1rem;
          img {
            width: 200px;
          }
        }
        @media (max-width: 364px) {
          margin-top: 2rem;
          img {
            width: 180px;
          }
        }
        img {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0%, -40%);
          display: block;
          max-width: 260px;
          max-height: 260px;
          height: auto;
          width: auto;
          z-index: 1;
        }
      }
      .white-text {
        color: $offerBox;
        .btn {
          color: #000;
          background-color: #eeeeee;
        }
      }
      .black-text {
        color: #000;
        .btn {
          color: $offerBox;
          background-color: #000;
        }
      }
      @media (max-width: 575px) {
        height: 280px;
        .text-section {
          margin-top: 0;
          top: 1rem;
        }
      }
    }
  }
}
.dealsYouCannotMissMainDiv {
  .itsMainDiv {
    height: 250px;
  }
}
.itsMainDiv {
  width: 45%;
  // height: 250px;
  // background-color: grey;
  border-radius: 10px;
  margin: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // cursor: pointer;
  .ContainDiv {
    width: 100%;
    height: 30%;
    // background-color: red;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    padding: 20px;
    text-align: left;
    .dealsHeading {
      font-weight: 800;
      font-size: 30px;
    }
    .dealsSubHeading {
      font-size: 18px;
    }
  }
  .containImageDiv {
    width: 100%;
    height: 70%;
    // background-color: steelblue;
    display: flex;
    padding: 20px;

    a {
      text-decoration: none;
    }

    .shopButton {
      width: 120px;
      height: 40px;
      background-color: whitesmoke;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #231f20;
      cursor: pointer;
    }

    img {
      position: absolute;
      // bottom: 0;
      right: 0;
      width: auto;
      height: 60%;
      margin-right: 20px;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .itsMainDiv {
    width: 100%;
    .ContainDiv {
      .dealsHeading {
        font-size: 18px;
      }
      .dealsSubHeading {
        font-size: 15px;
      }
    }
    .containImageDiv {
      flex-direction: column;
      img {
        height: 40%;
        // margin-right: 5px;
        margin-top: 30px;
      }
    }
    // Button {
    //   position: absolute;
    //   left: 0;
    //   margin-top: 100px;
    //   margin-left: 10px;
    // }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .itsMainDiv {
    height: 350px;
  }
}
