@import "variable";
.feature-section {
  padding: 0 2.25rem;
  a {
    text-decoration: none;
    &:focus {
      text-decoration: none;
    }
    .card {
      border-radius: 10px;
      align-items: center;
      -moz-box-shadow: 0 5px 5px 0 #ccc;
      -webkit-box-shadow: 0 5px 5px 0 #ccc;
      box-shadow: 0 5px 5px 0 #ccc;
      margin-bottom: 1rem;
      .brand-block {
        background-color: $dealsBg4;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 350px;
        padding: 3rem 0;
        position: relative;
        text-align: center;
        img {
          position: relative;
          padding-top: 2rem;
          object-fit: contain;
          object-position: center;
          object-fit: contain;
          height: 200px;
        }
      }
      .card-body {
        width: 100%;
        text-align: center;
        background: #fff;
        color: #000;
      }
    }
  }
}