@import "variable";
.best-buy {
  .shop-best-card {
    // height: 220px;
    height: 100%;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
      .card {
        align-items: center;
        height: 100%;
        border-radius: 25px;
        &:hover {
          -moz-box-shadow: 0 1px 5px 5px #ccc;
          -webkit-box-shadow: 0 1px 5px 5px #ccc;
          box-shadow: 0 1px 5px 5px #ccc;
        }
        cursor: pointer;

        .card-img-top {
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
          background-color: white;
          padding: 5px;
          height: 100px;
          // height: 70%;
          object-fit: contain;
          @media (max-width: 767px) {
            height: 100px !important;
          }
        }
        .card-body {
          width: 100%;
          height: 80px;
          text-align: center;
          background: #fff;
          overflow: hidden;
          color: #000;
          border-bottom-left-radius: 25px;
          border-bottom-right-radius: 25px;
          .card-title {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
    margin-bottom: 2rem;
    @media (max-width: 375px) {
      height: 200px;
      margin-bottom: 1rem;
      flex-wrap: wrap;
      a {
        .card {
          .card-img-top {
            height: 60%;
          }
          .card-body {
            .card-title {
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
    @media (max-width: 359px) {
      height: 200px;
      margin-bottom: 1rem;
      a {
        .card {
          .card-img-top {
            height: 55%;
          }
          .card-body {
            .card-title {
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
.modelMainDiv {
  margin: 0;
  padding: 0;

  .productModalInnerDiv {
    width: auto;
    height: auto;
    .productModalLeftDiv {
      width: 300px;
      height: 300px;
    }
    .productodalRightDiv {
      width: 50%;
      height: 70%;
      .descriptionDiv {
        width: 100%;
        height: 200px;
      }
      .addTocartButtonDiv {
        width: 100%;
        height: 70px;
        .minusIcon {
          font-size: 20px;
        }
        .productQuantity {
          font-size: 40px;
        }
        .plusIcon {
          font-size: 20px;
        }
        .addToCartButton {
          width: 150px;
          height: 50px;
          border-radius: 20px;
          background-color: green;
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .modelMainDiv {
    .productModalInnerDiv {
      flex-direction: column;
      .productModalLeftDiv {
        width: auto;
        height: auto;
      }
      .productodalRightDiv {
        width: auto;
        height: auto;
      }
    }
  }
}

.best-buy-random {
  .shop-best-card-random {
    height: 100%;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
      .card {
        align-items: center;
        height: 100%;
        border-radius: 25px;
        &:hover {
          -moz-box-shadow: 0 1px 5px 5px #ccc;
          -webkit-box-shadow: 0 1px 5px 5px #ccc;
          box-shadow: 0 1px 5px 5px #ccc;
        }
        cursor: pointer;

        .card-img-top {
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
          background-color: white;
          padding: 0.5px;
          // height: 70%;
          max-height: 175px;
          object-fit: contain;
          @media (max-width: 767px) {
            height: 200px !important;
          }
        }
        .card-body {
          width: 100%;
          text-align: center;
          background: #fff;
          height: 80px;
          overflow: hidden;
          color: #000;
          border-bottom-left-radius: 25px;
          border-bottom-right-radius: 25px;
          .card-title {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
    margin-bottom: 1.5rem;
    @media (max-width: 375px) {
      // height: 250px;
      height: 100%;
      margin-bottom: 1rem;
      flex-wrap: wrap;
      a {
        .card {
          .card-img-top {
            // height: 60%;
            height: 70%;
            object-fit: contain;
          }
          .card-body {
            .card-title {
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
    @media (max-width: 359px) {
      // height: 250px;
      height: 100%;
      margin-bottom: 1rem;

      a {
        .card {
          .card-img-top {
            // height: 55%;
            height: 70%;
            object-fit: contain;
          }
          .card-body {
            .card-title {
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
