@import "variable";

.recentOrdersMainDiv {
  background-color: $buyBg;
  border-radius: 10px;
}

@media (max-width: 545px) {
  .recentOrdersMainDiv {
    overflow-x: scroll;
  }
}
