.serviceMainCardDiv {
  .serviceHeading {
    font-size: 17px;
    font-weight: 600;
    // line-height: 1.5;
    // letter-spacing: 1.5px;
    letter-spacing: 1px;
    line-height: 1.7;
    word-spacing: 2px;
    text-align: justify;
  }
  .serviceDescription {
    font-size: 15px;
    // line-height: 1.5;
    // letter-spacing: 1.5px;
    letter-spacing: 1px;
    line-height: 1.7;
    word-spacing: 2px;
    text-align: justify;
  }
}
