@import "variable";

// .ProductSectionProductDiv {
//   height: auto;
// }

.imgTopCategoryPageSectionFirst {
  // background-color: red;
  height: 200px;
  width: 100%;
  .imgSize {
    max-height: 200px;
  }
  @media (max-width: 600px) {
    height: 250px;
    .imgSize {
      max-width: 100%;
      height: 150px;
    }
  }
}
.imgTopCategoryPageSectionFirstModal {
  height: 300px;
  width: 100%;
  .imgSize {
    max-width: 100%;
    height: auto;
  }
}
// .productModalInnerDiv {
//   position: relative;
// }
.closeButtonProductModal {
  position: absolute;
  // cursor: pointer;
  // margin-top: -80px;
  // margin-right: -10px;
  // background-color: white;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  border: 2px $primary solid;
  display: none;
  margin: 10px;
}

// .product:hover {
//   background-color: rgb(209, 197, 197);
// }
.product:hover {
  border: 0.5px solid #5f5f60ae !important;
}
.closeButtonProductModalDeskTop {
  // position: absolute;
  cursor: pointer;
  // margin-top: -80px;
  // margin-right: -10px;
  background-color: white;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // top: 0;
  // right: 0;
  border: 2px $primary solid;
  // display: none;
  // margin: 10px;
  position: absolute;
  top: 0;
  right: 0;
}
.descriptionDiv {
  height: 150px !important;
  overflow: hidden;
}
.productQuantity {
  font-size: 35px;
  font-weight: 600;
}
Button span {
  font-size: 14px;
}

@media (max-width: 812px) {
  .productModalInnerDiv {
    height: auto !important;
    flex-direction: column;
    margin-top: 30px;
    // background-color: yellowgreen;
  }

  .descriptionDiv {
    height: auto !important;
  }
  .addTocartButtonDiv {
    flex-direction: column;
  }
  .closeButtonProductModal {
    display: block;
  }
  .closeButtonProductModalDeskTop {
    display: none;
  }
}

@media (max-width: 320px) {
  .productodalRightDiv {
    width: auto;
    .descriptionDiv {
      width: 90% !important;
    }
    .addTocartButtonDiv {
      width: 90% !important;
    }
  }
}
