@import "variable";

.shippingPageTopText {
  font-weight: 600;
  font-size: 17px;
  // line-height: 1.5;
  // letter-spacing: 1.5px;
  letter-spacing: 1px;
  line-height: 1.7;
  word-spacing: 2px;
  text-align: justify;
  a {
    text-decoration: underline;
    color: rgb(114, 114, 189);
    cursor: pointer;
  }
  a:hover {
    text-decoration: underline;
    color: rgb(114, 114, 189);
    cursor: pointer;
  }
}

// .tabMenuTable {
//   tr {
//     cursor: pointer;
//     a {
//       text-decoration: none;
//       color: black;
//     }
//   }
//   tr:hover {
//     background-color: #45464e;
//   }
// }
.tabMenu {
  ul {
    border: 1px black solid;
    display: flex;
    justify-content: space-evenly;

    li {
      font-size: 17px;
      font-weight: bold;
      border: 1px black solid;
      width: 25%;
      text-align: center;
      // .active {
      //   background-color: #45464e;
      //   color: white;
      // }

      a {
        text-decoration: none;
      }
      span {
        font-size: 17px;
        color: black;
        // line-height: 1.5;
        // letter-spacing: 1.5px;
        letter-spacing: 1px;
        line-height: 1.7;
        word-spacing: 2px;
        text-align: justify;
      }
    }
  }
}
// .shippingLocationTabMenu {
//   font-size: 20px;
//   font-weight: bold;
// }

.shippingAddress {
  font-size: 15px;
  font-weight: 500;
}
.privacySpan {
  font-size: 17px;
  text-decoration: underline;
  // line-height: 1.5;
  // letter-spacing: 1.5px;
  letter-spacing: 1px;
  line-height: 1.7;
  word-spacing: 2px;
  text-align: justify;
}
.shippingPrivacyPolicyDesc {
  // line-height: 1.5;
  // letter-spacing: 1.5px;
  // font-size: 15px;
  letter-spacing: 1px;
  line-height: 1.7;
  word-spacing: 2px;
  text-align: justify;
}

table {
  text-align: center;
  // line-height: 1.5;
  // letter-spacing: 1.5px;
  letter-spacing: 1px;
  line-height: 1.7;
  word-spacing: 2px;
  // text-align: justify;
}

@media (max-width: 800px) {
  .tabMenu {
    ul {
      border: 1px black solid;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      li {
        // font-size: 20px;
        // font-weight: bold;
        // border: 1px black solid;
        width: 100%;
        text-align: center;
        a {
          text-decoration: none;
        }
        span {
          color: black;
        }
      }
    }
  }
}
