@import "variable";
.categoryListMenuMainDiv {
  position: relative;
  // background-color: yellowgreen;
  // height: 320px;
  height: auto;
  .categoryNameHeader {
    // background-color: red;
    height: 35px;
    // .categoryNameHeaderName {
    //   font-size: 1rem;
    // }
  }
  .subCategoryItems {
    display: none;
    .subMenuMainDivHeader {
      background-color: #ffffff;
      border: 1px rgb(216, 210, 210) solid;
      // height: 320px;
      height: auto;
      width: 450px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      // box-shadow: 4px 4px rgb(216, 210, 210);
      -webkit-box-shadow: 19px 20px 23px 6px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 19px 20px 23px 6px rgba(0, 0, 0, 0.75);
      box-shadow: 19px 20px 23px 6px rgba(0, 0, 0, 0.75);

      .subCatList {
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        // .subCatListName {
        //   font-size: 1rem;
        // }
      }
    }
  }
  .categoryNameHeader:hover {
    background-color: $primary;
    color: white;
  }
  .mainCategory:hover {
    background-color: $primary;
  }
  .mainCategoryArrow {
    font-size: 20px;
    flex: 1;
    color: white;
  }
  .mainCategory:hover {
    // position: relative;
    .categoryNameHeader {
      background-color: $primary;
    }
    .subCategoryItems {
      display: block;
      position: absolute;
      // right: 0;
      // top: 0;
      margin-top: -25px;
      ul {
        // margin-top: -20%;

        // margin-top: -10%;
        // margin-left: 100%;
        // width: 100%;

        margin-top: 0%;
        margin-left: 6%;
        width: 100%;
      }
    }
  }
}

@media (max-width: 990px) {
  // #navbarTogglerDemo03 {
  //   display: none !important;
  // }
  .navbar-collapse {
    position: fixed;
    // width: 300px;
    width: 100vw;
    height: 100vh !important;
    background-color: white;
    // transition: 1s;
    z-index: 9999;
    left: 0px;
    top: 0px;
    padding: 10px;
    overflow-y: scroll;
  }
  .dropdown-menu {
    height: auto;
    .subCategoryItems {
      display: none;
      .subMenuMainDivHeader {
        width: 100%;
        height: auto;
        box-shadow: 0 0;
      }
    }
    .categoryAndIconMainDiv {
      // flex-direction: column;
      // background-color: red;
      border-bottom: 1px rgb(194, 189, 189) solid;
      margin-bottom: 5px;
      // .mainCategoryArrow {
      //   position: absolute;
      //   right: 0;
      // }
      .mainCategoryArrow {
        color: black;
      }
    }

    .categoryNameHeader:hover {
      background-color: white;
    }
    .mainCategory:hover {
      background-color: white;
    }
    .mainCategory {
      .categoryAndIconMainDiv {
        // flex-direction: column !important;
        .subCategoryItems {
          display: none;
        }
      }
      .subCategoryItems {
        display: none;
        position: static;
        // right: 0;
        width: 100%;
        ul {
          margin-top: 0%;
          margin-left: 0%;
          width: auto;
        }
      }
    }

    // Test 3
    .mainCategoryMobileSubCatDisplay {
      .categoryAndIconMainDiv {
        // flex-direction: column !important;
        .subCategoryItems {
          // display: block;
          position: fixed;
          top: 0;
          bottom: 0;
          right: 0;
          width: 100vw;
          background-color: #e9ecef;
          // background-color: #2e2e2e;
          overflow: scroll;
          .subMenuMainDivHeader {
            // height: 100vh;
            height: auto;
            border: 0px rgb(216, 210, 210) solid;
            background-color: #e9ecef;
            // background-color: #2e2e2e;
            .subCatList {
              width: 98vw !important;
              background-color: #e9ecef !important;
              margin-bottom: 10px !important;
              text-transform: uppercase;
              display: flex;
              justify-content: flex-start !important;
              .subCatListName {
                font-size: 22px !important;
              }
            }
          }
        }
      }
    }
    // Test 3 ends
  }
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
  }
  .openOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}
