@import "variable";
footer {
  // border-top: 1px solid $textColor;
/*   .footer-image {
    margin: 1rem 0;
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
    background-image: url("../../assets/img/rectangle4.jpg");
  } */
  .video-container {
    height: 320px;
    position: relative;
    video {
      height: 300px;
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      z-index: 0;
    }
  }
  .footer-top {
    background-color: #f4f4f4;
    padding: 1rem;
    .footer-desc {
      .no-text-transform {
        text-transform: none;
      }
      padding: 0;
      ul {
        padding: 0;
        font-size: 1.1rem;
        color: $textColor;
        list-style: none;
        text-transform: capitalize;
        font-weight: 600;
        li {
          font-weight: 400;
          font-size: 0.9rem;
          margin: 1rem 0;
          text-transform: capitalize;
        }
        .contact-content {
          margin-left: 15px;
        }
        a {
          text-decoration: none;
          color: $textColor;
        }
      }
      .social_list {
        margin: 0 0 0 40px;
        display: inline-block;
        // @media only screen and (max-width: 1439px){
        //     margin: 0 160px 0 22px;
        // }
        // @media only screen and (max-width: 1599px){
        //     margin: 0 115px 0 22px;
        // }
        // @media only screen and (max-width: 1600px){
        //     margin: 0 35px 0 22px;
        // }
        li {
          display: inline-block;
          vertical-align: top;
          margin: 5px 10px;
          width: 34px;
          a {
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            position: relative;
            display: block;
            &:before {
              content: "";
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border-radius: 50%;
              position: absolute;
              border: #606d7a solid 6px;
              -webkit-animation-duration: 1s;
              animation-duration: 1s;
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    background-color: $primary;
    padding-bottom: 5px;
    .ft_bottom {
      color: #606d7a;
      font-size: 14px;
      font-weight: 300;
      overflow: hidden;
      padding-top: 10px;
      @media (min-width: 991px) {
        .flex-md-row-reverse {
          -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
        }
      }

      .footer-bottom-logo {
        @media (max-width: 767px) {
          text-align: center;
        }
      }
      .social-logos {
        margin-top: 1.5rem;
        a {
          padding: 0 0.5rem;
          i {
            color: #fff;
            font-size: 2rem;
          }
        }
        @media (max-width: 991px) {
          text-align: right;
        }
        @media (max-width: 767px) {
          text-align: center;
        }
      }
      .copyright {
        margin-top: 1.6rem;
        color: #fff;
        font-size: 1rem;
        letter-spacing: 1px;
        font-weight: 600;
        text-align: right;
        @media (max-width: 991px) {
          text-align: center;
        }
      }
    }
    @media (max-width: 574px) {
      .copyright {
        text-align: center !important;
        margin-bottom: 10px;
      }
    }
  }
}
