.categoryMainDIv {
  border: 1px silver solid;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  cursor: pointer;
  a {
    text-decoration: none;
    color: black;
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.filterCategory {
  border-bottom: 2px silver solid;
}
