@import "variable";

.user-profile {
  .pageTitle {
    font-size: 25px;
  }

  h6 {
    width: 100%;
    text-align: center;
    border-bottom: 2px solid #000;
    line-height: 0.1em;
    margin: 20px 0px 20px;
    span {
      cursor: pointer;
      background: #fff;
      padding: 7px 15px;
      color: white;
      text-decoration: none;
      background-color: yellowgreen;
      border-radius: 25px;
      &:hover {
        color: white;
      }
    }
  }
}

.editProfileCloseBtn {
  cursor: pointer;
  background-color: white;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  border: 2px $primary solid;
  position: absolute;
  top: 0;
  right: 0;
}
