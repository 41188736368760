.status {
  margin-bottom: 2rem;
}
.payme-section {
  overflow: hidden;
  margin-bottom: 2rem;
  img {
    -webkit-animation-name: opacity;
    animation-name: opacity;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    // height: 400px;
    // width: 400px;
    // @media (max-width: 991px) {
    //   height: 250px;
    // }
  }
  .pay-desc {
    margin-top: 2rem;
    text-align: center !important;
    color: #362415;
    font-weight: 600;
    img {
      width: 140px;
      height: 100px;
      margin-top: -40px;
      margin-bottom: -20px;
    }
  }
  .payme-steps {
    list-style: none;
    text-align: justify;
    line-height: 2;
    padding: 0;
    .payment-steps {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .paymeStep {
      font-size: 1rem;
      .numberCircle {
        border-radius: 50%;
        padding: 2px 8px;
        border: 2px solid #666;
        color: #fff;
        background: #666;
        margin-right: 10px;
      }
      .one {
        &:before {
          content: "1";
        }
      }
      .two {
        &:before {
          content: "2";
        }
      }
      .three {
        &:before {
          content: "3";
        }
      }
    }
  }
}
