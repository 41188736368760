.brandHomePageComponentMainDiv {
  @media (max-width: 768px) {
    display: none;
  }
  border-bottom: 1px #b5b5b5 solid;
  .innerRow {
    min-height: 70px;
    max-height: 70px;
    div {
      min-height: 70px;
      max-height: 70px;
      overflow: hidden;
      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
      }
    }
  }
}
