.hero_div {
  height: 70vh;
  .heroImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.pageTitle {
  font-size: 1.5rem;
  font-weight: bold;
}
// .menuImage {
//   height: 80vh;
//   img {
//     height: 100%;
//   }
// }
.downloadBtn {
  background-color: transparent;
  color: transparent;
  outline: none;
  border: none;
  &:hover {
    background-color: transparent;
    color: transparent;
  }
  &:focus {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
