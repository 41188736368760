@import "variable";
.jumbotronMainDiv {
  background: linear-gradient(rgb(226, 223, 223), white);
  background-image: url("../../assets/Others/socialResponsibility.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px !important;
  .socialResponsibilityheadingText {
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 1.7;
    word-spacing: 2px;
    text-align: justify;
    color: white;
  }
}
.socialResponsibilityText {
  font-size: 18px;
  // line-height: 1.5;
  // letter-spacing: 1.5px;
  letter-spacing: 1px;
  line-height: 1.7;
  word-spacing: 2px;
  text-align: justify;
}
