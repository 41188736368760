.pmCard {
  // background-color: #21920f;
  background-color: #f8f8fa;
  //   color: white;
  border-radius: 5px;
  font-size: 15px;
  border: 1px black solid;
  .linkWithPaymentOption {
    text-decoration: none;
    color: black;
  }
  cursor: pointer;
  i {
    margin-right: 10px;
  }
}
