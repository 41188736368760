.searchPage {
  .filterBox {
    .filterTitle {
      font-size: 1.5rem;
      font-weight: bold;
    }
    width: 100%;
    max-width: 17rem;
    margin: 45px auto;
    .list-group-item:hover {
      cursor: pointer;
    }
    .list-group-item.active {
      background-color: #21920f;
      border: none;
    }
  }
}
