.Text500heading {
  font-weight: 900;
  font-size: 100px;
  color: #e71925;
}
.text500 {
  font-size: 20px;
}

@media (max-width: 768px) {
  .error500imageDiv {
    display: none !important;
  }
}
