.searchBoxMainDiv {
  //   background-color: green;
  border-radius: 20px;
}
.searchBarProductPage {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  outline: none;
  border-width: 0ch;
  height: 40px;
  background-color: #dfe8e1;
}
.searchIcon {
  height: 40px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

@media only screen and (max-width: 600px) {
  .searchAndFilterBar {
    flex-direction: column;
  }
}
