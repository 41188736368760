@import "variable";
.fpMaiDiv {
  -webkit-box-shadow: 10px 10px 5px -6px rgba(179, 165, 179, 1);
  -moz-box-shadow: 10px 10px 5px -6px rgba(179, 165, 179, 1);
  box-shadow: 10px 10px 5px -6px rgba(179, 165, 179, 1);
  //   background-color: $buyBg;
  .npLabal {
    color: grey;
  }

  .fa {
    top: 13px;
    right: 1rem;
    color: grey;
  }

  .form-group a i {
    // font-family: FontAwesome;
    margin: 0 auto;
    font-size: 5rem;
    font-style: normal;
  }
}
