@import "variable";
.cart-section {
  margin-bottom: 2rem;
  .cart-table {
    thead {
      tr {
        th {
          font-size: 1.2rem;
        }
      }
    }
    tbody {
      tr {
        td {
          .text {
            font-size: 1.2rem;
            padding: 1rem;
          }
        }
        .cancel-product {
          padding: 0 0 0 2rem;
          font-size: 1.5rem;
          color: red;
        }
      }
      .sub-heading {
        td {
          font-size: 1.2rem;
        }
      }
    }
    @media (max-width: 464px) {
      i {
        font-size: 0.5rem;
      }
    }
  }
  .discount-section {
    .input-box {
      border-color: $buyBg;
      background-color: $buyBg;
      border-radius: 25px !important;
      padding: 1.3rem 0.5rem;
    }
    form {
      .form-control {
        &:focus {
          outline: none;
          box-shadow: none;
          border-color: $buyBg;
        }
      }
      button {
        background-color: $primary;
      }
    }
    button {
      border-radius: 25px;
      color: #fff;
      margin-left: 0.5rem;
      font-size: 16px;
      padding: 0.5rem 1rem;
      font-weight: 600;
      &:hover {
        color: #fff;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .checkout-section {
    text-align: right;
    button,
    a {
      background-color: $primary;
      border: none;
      border-radius: 25px;
      color: #fff;
      margin-left: 0.5rem;
      font-size: 16px;
      padding: 0.5rem 1rem;
      font-weight: 600;
      &:hover {
        color: #fff;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  @media (max-width: 1199px) {
    button {
      width: 40% !important;
    }
  }
  @media (max-width: 991px) {
    button {
      width: 60% !important;
    }
    .discount-submit {
      margin-top: 0.5rem;
    }
  }
  @media (max-width: 767px) {
    .discount-section {
      text-align: center;
    }
    form {
      align-items: center;
      align-content: center;
      .form-control {
        text-align: center;
      }
      text-align: center;
    }
    .checkout-section {
      margin-top: 1rem;
      text-align: center;
    }
  }
}
