.productListDiv {
  flex-wrap: wrap;
}

.productListImgTop {
  // background-color: red;
  height: 150px;
  width: 100%;
  .imgSize {
    width: 100%;
    height: 100%;
  }
}

.filterSubmitBtn {
  width: 100px;
  height: 40px;
  background-color: green;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .MainContainer {
    flex-direction: column;
  }
}
