.advTypeMainCard {
  overflow: hidden;
  cursor: pointer;
  .advTypeImgDiv {
    height: 300px !important;
  }
  .advTypeConetentDiv {
    height: 300px !important;
    width: 100%;
    position: absolute;
    bottom: -100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: none;
    transition: 0.5s;
    color: white;
  }

  &:hover {
    .advTypeConetentDiv {
      display: block !important;
      bottom: 0;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      text-align: center !important;
    }
  }
}
