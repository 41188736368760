.app-img {
  margin: 1rem 0;
  text-align: center;
  height: 60vh;
  img {
    padding: 1rem;
    max-width: 90%;
    max-height: 100%;
  }
}

@media (max-width: 1024px) {
  .app-img {
    height: auto;
  }
}
