@import "variable";
.specialEventHeaderText {
  font-size: 35px;
  letter-spacing: 1px;
  line-height: 1.7;
  word-spacing: 2px;
  text-align: center;
  color: rgb(13, 13, 73);
}
.headingTopSE {
  font-size: 17px;
  font-weight: 600;
  // line-height: 1.5;
  // letter-spacing: 1.5px;
  letter-spacing: 1px;
  line-height: 1.7;
  word-spacing: 2px;
  text-align: justify;
  color: #e26021;
}
.descSE {
  font-size: 17px;
  // line-height: 1.5;
  // letter-spacing: 1.5px;
  letter-spacing: 1px;
  line-height: 1.7;
  word-spacing: 2px;
  text-align: justify;
}

@media (max-width: 767px) {
  .specialEventHeaderText {
    font-size: 20px;
    display: flex;
  }
  .imgDivSE {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
