.orderConfirmBtn {
  border: 1px #21920f solid;
  width: 100%;
  font-size: 18px;
  background-color: #e7e7e7;
  color: #21920f;
}
.serviceDiv {
  border-top: 2px #e7e7e7 solid;
  margin-top: 5px;
}
.vatDiv {
  border-bottom: 2px #e7e7e7 solid;
  margin-bottom: 5px;
}
