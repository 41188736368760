.Text404heading {
  font-weight: 900;
  font-size: 100px;
}
.text404 {
  font-size: 20px;
}

@media (max-width: 768px) {
  .error404imageDiv {
    display: none !important;
  }
}
