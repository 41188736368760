@import "variable";
.couponCodeMainDiv {
  //   background-color: $buyBg;
  background-color: #ffc66a;
  padding: 2px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(194, 190, 194, 1);
  -moz-box-shadow: 10px 10px 5px 0px rgba(194, 190, 194, 1);
  box-shadow: 10px 10px 5px 0px rgba(194, 190, 194, 1);
  // height: 40px;
  height: auto;
  border: 1px #374548 dashed;
  h3 {
    color: #374548;
    font-size: 20px !important;
  }
}
