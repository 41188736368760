.advcarousalMainDiv {
  margin-top: -15px !important;
}
.advWithUsHeadingSpan {
  font-size: 17px;
  font-weight: 600;
  // line-height: 1.5;
  // letter-spacing: 1.5px;
  letter-spacing: 1px;
  line-height: 1.7;
  word-spacing: 2px;
  text-align: justify;
}
.advWithUsDescSpan {
  font-size: 15px;
  // line-height: 1.5;
  // letter-spacing: 1.5px;
  letter-spacing: 1px;
  line-height: 1.7;
  word-spacing: 2px;
  text-align: justify;
}
.carousel-indicators {
  right: inherit;
  margin-bottom: 70px !important;
  margin-left: 50px !important;
}
.carousel-indicators li {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

@media (max-width: 776px) {
  .carousel-indicators {
    right: inherit;
    margin-bottom: 50px !important;
    margin-left: 50px !important;
  }
}
