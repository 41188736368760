@import "variable";
$green: #21920f;
$darkGrey: #4d4d4d;
$addTo: #8e8e8e;
$addToDarkN: #727272;
$quantity: #dfdfdf;

.product-page {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  .card {
    margin: 5px;
    border-radius: 25px;
    &:hover {
      -moz-box-shadow: 0 1px 5px 5px #ccc;
      -webkit-box-shadow: 0 1px 5px 5px #ccc;
      box-shadow: 0 1px 5px 5px #ccc;
    }
    cursor: pointer;
    .product-image {
      display: flex;
      justify-content: center;
    }
    .card-img-top {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      padding: 5px;
      object-fit: contain;
      width: 200px;
      height: auto;
      max-height: 200px;
    }
  }
}
.back {
  color: $green;
  font-size: 1rem;
  font-weight: 500;
  .backIcon {
    padding-right: 6px;
  }
}
.back:hover {
  cursor: pointer;
}
.btn-addtocart {
  background-color: $green;
  color: #fff;
}
.btn-addtowishlist {
  background-color: $addTo;
  color: white;
}
.btn-big {
  min-width: 200px;
  width: fit-content;
  height: 50px;
  border-radius: 10px;
  font-weight: 600;
}
.btn-addtocart:hover {
  background-color: $primaryDarkN;
  color: #fff;
}
.btn-addtowishlist:hover {
  background-color: $addToDarkN;
  color: #fff;
}

.product-image-col {
  display: flex;
  justify-content: center;
  .product-image {
    border: 0.5px solid #5f5f60ae;
    border-radius: 10px;
    img {
      padding: 20px 0px;
      height: 300px;
      width: 300px;
      object-fit: contain;
    }
  }
}
.product-details {
  max-width: 615px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .stock-info {
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 10px;
  }
  .in-stock {
    color: $green;
  }
  .out-of-stock {
    color: $danger;
  }
  .product-name {
    font-size: 1.5rem;
    font-weight: 700;
    color: $darkGrey;
    margin-top: 10px;
  }
  .product-price {
    // margin-top: 10px;
    font-size: 2.5rem;
    font-weight: 700;
  }
  .product-description {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: 400;
    color: gray;
  }
  .product-actions {
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-wrap: wrap;
    margin: 20px 0px;

    .product-quantity {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      margin: 5px 0px;
      margin-right: 20px;
      .action {
        margin-top: 10px;
        display: inline;
        background-color: $quantity;
        width: 45px;
        height: 45px;
        line-height: 2.5;
        vertical-align: middle;
        text-align: center;
        color: #333;
        margin: 0;
        padding: 5px;
        font-size: 13px;
        cursor: pointer;
        i {
          font-size: 14px;
        }
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
      input {
        display: inline;
        width: 45px;
        height: 45px;
        line-height: 1.65;
        margin: 0;
        padding: 0;
        border: 1px solid #eee;
        text-align: center;
      }
      input:focus {
        outline: 0;
      }
    }
    .actionButtons {
      display: flex;
      //   justify-content: center;
      flex-wrap: wrap;
      button {
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .addToCart {
      margin-right: 20px;
    }
    @media (max-width: 550px) {
      .product-quantity {
        margin-bottom: 20px;
      }
    }
  }
}
