@import "variable";
.productNameAndViewAllCard {
  // width: 80px;
  height: 331px;
  // background-color: #daf5d4;
  background-color: $primary;
  color: white;
  a {
    color: white;
    text-decoration: underline;
  }
  a:hover {
    color: black;
  }
}

@media only screen and (max-width: 576px) {
  .productNameAndViewAllCard {
    height: 331px;
  }
}
