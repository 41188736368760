@import "variable";

.card-img {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  // width: 80%;
  // padding: 1rem;
  align-self: center;
}

.card-title {
  margin-bottom: 0.3rem;
}

.cat {
  display: inline-block;
  margin-bottom: 1rem;
}

.fa-user {
  margin-left: 1rem;
}

.card-footer {
  font-size: 0.8rem;
}
