.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border: 1px solid darkgrey;
  color: #000;
  padding: 2rem 0.5rem;
  font-size: 1.2rem;
  text-transform: capitalize;
  margin-left: -10px;
}
.nav-pills .nav-link img {
  max-width: 24% !important;
}

.nav-pills .nav-link .paymeLogo {
  max-width: 40% !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #21920f;
  padding: 2rem 0.5rem;
}
.nav-pills .nav-link.active img {
  filter: brightness(0) invert(1);
}

@media (max-width: 452px) {
  .nav-pills .nav-link img {
    max-width: 25% !important;
  }
  .nav-pills .nav-link {
    font-size: 1rem;
    padding: 2rem 0rem;
  }
  .nav-pills .nav-link span{
    display: block;
    margin-top: 5px;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    padding: 2rem 0rem;
  }
}
@media (max-width: 400px) {
  .nav-pills .nav-link img {
    max-width: 25% !important;
  }
  .nav-pills .nav-link {
    font-size: 1rem;
  }
  .nav-pills .nav-link span{
    display: block;
    margin-top: 5px;
  }
}
@media (max-width: 374px) {
  .nav-pills .nav-link img {
    max-width: 30% !important;
  }
  .nav-pills .nav-link {
    font-size: 0.8rem;
  }
  .nav-pills .nav-link span{
    display: block;
    margin-top: 5px;
  }
}
.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  padding: 0rem 0.5rem;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}