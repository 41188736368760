.mainDiv {
  text-align: left;
  padding: 1rem 2rem;
  margin: 1rem 0;
  span {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 1px;
  }
}
