@import "variable";

.userDetailsComponentsMainDiv {
  background-color: $buyBg;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  //   .userDetailsDiv {
  //     border-bottom: 1px grey solid;
  //   }
}

@media only screen and (max-width: 768px) {
  .userDetailsComponentsMainDiv {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    .subDiv {
      flex-direction: column;
    }
  }
}
