@import "variable";
* {
  scroll-behavior: smooth !important;
}
.backToTopMainDiv {
  background-color: $secondary;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  position: fixed;
  right: 5px;
  bottom: 55px;
  margin: 5px;
  z-index: 9999;
}
