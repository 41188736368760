@import "variable";
.owl-carousel {
  .owl-stage-outer {
    z-index: -1;
  }
  .owl-item {
    &:focus {
      outline: none;
    }
  }
}
.owl-theme {
  // .owl-nav.disabled + .owl-dots {
  //   margin-top: 1rem !important;
  // }
  .owl-dots {
    .owl-dot.active {
      span {
        background: $buyBg !important;
      }
      &:focus {
        outline: none;
      }
    }
    .owl-dot {
      margin: 0 -5px !important;
      span {
        background: $buyBg !important;
      }
      &:focus {
        outline: none;
      }
    }
  }
}

.featured {
  .owl-nav.disabled + .owl-dots {
    margin-top: 1rem !important;
  }
  .owl-dots {
    .owl-dot.active {
      span {
        background: $secondary !important;
      }
      &:focus {
        outline: none;
      }
    }
    .owl-dot {
      margin: 0 -5px !important;
      span {
        background: $secondary !important;
      }
      &:focus {
        outline: none;
      }
    }
  }
}

.brand {
  .owl-nav.disabled + .owl-dots {
    margin-top: 1rem !important;
  }
  .owl-dots {
    .owl-dot.active {
      span {
        background: $secondary !important;
      }
      &:focus {
        outline: none;
      }
    }
    .owl-dot {
      margin: 0 -5px !important;
      span {
        background: $secondary !important;
      }
      &:focus {
        outline: none;
      }
    }
  }
}

.block {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
  .img_holder {
    display: inline-block;
    vertical-align: middle;
    border-radius: 5%;
    overflow: hidden;
    background-color: $buyBg;
    img {
      width: 100%;
      object-fit: contain;
      height: 80px;
      padding: 1rem 0.5rem;
    }
  }
  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  .text_holder {
    width: 100px;
    margin-top: 15px;
    font-weight: 600;
    line-height: 20px;
    padding: 0 0 0 5px;
    display: inline-block;
    vertical-align: middle;
    span {
      font-size: 12px;
      font-weight: 400;
    }
  }
  p {
    font-size: 14px;
    color: $secondary;
    @media (max-width: 574px) {
      font-size: 14px;
    }
  }
}
.BrandNameAndImage {
  // background-color: yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  border-radius: 10px;
  overflow: hidden;
  // border: 1px solid;
  padding: 10px;
  // box-shadow: 5px 5px #888888;

  .BrandLogo {
    width: 30%;
    height: 100%;
    background-color: rgb(197, 187, 187);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .BrandInfo {
    width: 70%;
    height: 100%;
    // background-color: green;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 10px;

    .brandName {
      width: 100%;
      height: auto;
      // background-color: orange;
      display: flex;
      // justify-content: center;
      align-items: center;
      text-align: left;

      span {
        font-weight: 700;
      }
    }
    .productList {
      width: 100%;
      height: auto;
      // background-color: orangered;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      display: flex;
      // justify-content: center;
      align-items: center;
      text-align: left;
    }
  }
}
