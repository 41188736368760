@import "variable";
.shop-cat-card {
  height: 220px;
  .card {
    align-items: center;
    height: 100%;
    .card-img-top {
      background-color: $dealsBg1;
      height: 150px;
      width: 100% !important;
      object-fit: contain;
    }
    .card-body {
      width: 100%;
      text-align: center;
      background: $buyBg;
    }
  }
  margin-bottom: 2rem;
}
