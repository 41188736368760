.customButtonMainDiv {
  border: 0;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    color: white;
    font-weight: 600;
  }
}
