.topHeading {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.7;
  word-spacing: 2px;
  text-align: justify;
}

.policyBox {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.7px;
  word-spacing: 1.5px;
  text-align: justify;
}
