@import "variable";
.schedule-delivery {
  padding: 2rem 1rem;
  .text-section {
    display: inline-block;
    position: relative;
    padding-top: 13%;
    .title {
      font-size: 1.8rem;
    }
    .text-on-right {
      text-align: right;
    }
    .desc {
      letter-spacing: 1px;
      word-spacing: 1px;
      font-size: 16px;
      opacity: 0.7;
      font-weight: 300;
    }
  }
  @media (max-width: 1199px) {
    .text-section {
      padding-top: 10%;
    }
  }
  @media (max-width: 991px) {
    .text-section {
      .title {
        font-size: 1.8rem;
      }
      .desc {
        font-size: 14px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 0 1rem;
    margin-top: -1rem;
    .imgDivOfStore {
      order: 1;
    }
    .text-on-right {
      text-align: left !important;
    }
  }
  .get-started {
    margin-top: 3rem;
    a {
      margin-top: 1rem;
      border-radius: 25px;
      font-size: 1.6rem;
      font-weight: 600;
      text-transform: uppercase;
      color: #fff;
      background-color: $primary;
    }
    @media (max-width: 991px) {
      a {
        font-size: 1.4rem;
      }
    }
  }
}
