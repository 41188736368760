.owl-carousel {
  .owl-stage-outer {
    z-index: -1;
  }
}
.owl-theme {
  .owl-nav.disabled + .owl-dots {
    margin-top: -2rem !important;
  }
  .owl-dots {
    .owl-dot.active {
      span {
        background: #fff !important;
      }
      &:focus {
        outline: none;
      }
    }
    .owl-dot {
      margin: 0 -5px !important;
      span {
        background: #fff !important;
      }
      &:focus {
        outline: none;
      }
    }
  }
}
