$primary: #21920f;
$primaryDark: #24481e;
$primaryDarkN: #1d830d;
$secondary: #231f20;
$featuredBg: #ffc759;
$shopBg: #7c03ed;
$buyBg: #ededed;
$dealsBg1: #7cd0ed;
$dealsBg2: #c05b5b;
$dealsBg3: #5bc064;
$dealsBg4: #ffc759;
$offerBox: #f9fbfb;
$textColor: #231f20;
$danger: #dc3545;
