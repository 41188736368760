.real-taste-of-india-wrapper {
  font-family: "Poppins", sans-serif;
  .side-by-side {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .banner-image {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .title {
    margin: 20px 0;
    p,
    a {
      margin: 0;
      font-size: 20px;
      color: #242424;
      font-weight: 400;
      svg {
        margin-left: 5px;
        color: #21920f;
      }
    }
  }
  .menu-items {
    .card {
      border-radius: 0;
      border: none;
      margin-bottom: 25px;
      .card-header {
        padding: 0 0 0 15px;
        background-color: #f6f6f6;
        border-bottom: 0;
        .menu-item {
          min-height: 100px;
          h2 {
            font-size: 30px;
            color: #21920f;
            font-weight: 500;
            padding-left: 25px;
            &::before {
              content: "";
              position: absolute;
              left: 15px;
              top: 10px;
              height: 80px;
              width: 6px;
              border-radius: 3px;
              background-color: #21920f;
            }
          }
          img {
            width: 250px;
            height: 100px;
            object-fit: cover;
          }
        }
      }
      .card-body {
        padding: 0;
        ul {
          margin: 20px 0 0;
          padding: 0;
          list-style: none;
          li {
            padding: 10px 45px;
            span {
              font-size: 25px;
              color: #242424;
              font-weight: 400;
              a {
                color: #242424;
                &:hover {
                  text-decoration: none;
                }
              }
            }
            .item-title {
              width: 40%;
            }
            .item-price {
              width: 30%;
              text-align: center;
            }
            .cart-icon {
              width: 30%;
              text-align: right;
            }
            &:hover span,
            &:hover span a {
              color: #21920f;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
