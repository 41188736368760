@import "variable";
// Header
.top-header {
  background-color: $primary;
  // aaded css
  height: 80px !important;
  // added css ends
  .logo {
    padding: 0 2rem 0 0.5rem;
    // margin: 0.7rem 0;
    margin-top: 0.3rem;
    // aaded css
    height: 70px !important;
    // added css ends
  }

  // Added Css
  .loginRightDivMainDiv {
    height: 40px !important;

    @media (min-width: 992px) {
      .loginAndCartMainDiv {
        height: 30px !important;
        // background-color: red;
        padding-top: 15px;
      }
    }
  }
  // Added Css Ends

  .search-bar {
    // padding: 1.5rem 0.5rem;

    // added css
    padding: 10px;
    // added css ends

    .search-icon-box {
      #basic-addon1 {
        border-top-left-radius: 40%;
        border-bottom-left-radius: 40%;
        border-color: #fff;
        i {
          font-size: 1.3rem;
          color: lightgrey;
        }
        &:after {
          content: "";
          margin-left: 0.8rem;
          height: 2.2rem;
          width: 0.1rem;
          background-color: lightgrey;
        }
      }
    }
    .input-search-box {
      height: 3.2rem;
      border: none;
      margin-top: 0;
      box-shadow: none;
      border-radius: 0;
      padding-bottom: 10px;
      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        color: lightgrey;
      }
    }
    .clear-icon-box {
      #basic-addon2 {
        border-color: #fff;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        i {
          margin-right: 5px;
          color: $dealsBg2;
          font-size: 1.3rem;
        }
      }
    }
    .search-bar-content {
      .productListItem:hover {
        background-color: #e6e6e64d;
        cursor: pointer;
        border-radius: 5px;
      }
      .productRow {
        display: flex;
      }
      .searchAll {
        display: flex;
        font-weight: bold;
        color: #4599ff;
        .searchIcon {
          margin-right: 5px;
          font-size: 1.3rem;
        }
        .searchString {
          padding-top: 2px;
        }
      }
      #basic-addon3 {
        border-color: #fff;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        i {
          margin-right: 5px;
          color: $dealsBg2;
          font-size: 1.3rem;
        }
      }
    }
    .search-bar-content::-webkit-scrollbar {
      width: 0.5em;
      border-radius: 10px;
    }
    .search-bar-content::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }
    .search-bar-content::-webkit-scrollbar-track-piece {
      border-radius: 10px;
    }
    .search-bar-content::-webkit-scrollbar-thumb {
      background-color: #a9a9a9;
      border-radius: 10px;
    }
    .search-bar-content {
      display: none;
      max-height: 250px;
      overflow-y: scroll;
      max-width: 97% !important;
      position: absolute;
      top: 60px;
      z-index: 99999;
      .search-bar-message {
        // margin-top: 15%;
        vertical-align: middle;
        text-align: center;
      }
      ul {
        list-style: none;
        // padding: 1rem;
        li {
          padding: 0.5rem 0;
          a {
            text-decoration: none;
            color: #000;
          }
        }
      }
      @media (max-width: 2560px) {
        max-width: 98.6%;
      }
      @media (max-width: 1900px) {
        max-width: 97.5%;
      }
      @media (max-width: 1440px) {
        max-width: 97.7%;
      }
      @media (max-width: 1199px) {
        max-width: 96.1%;
      }
      @media (max-width: 991px) {
        max-width: 98.1%;
      }
      @media (max-width: 574px) {
        max-width: 96.5%;
        top: 65px;
      }
      @media (max-width: 426px) {
        max-width: 95.5%;
      }
    }
  }
  .navbar-avatar {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin: auto 0.2rem;
  }
  .icons-shortcut {
    color: #fff;
    text-transform: uppercase;
    padding: 1.8rem 0;
    margin-right: 20px;
    font-size: 0.91rem;
    font-weight: 300;
    font-family: "Exo 2", "Montserret", sans-serif;
    .store-locator {
      cursor: pointer;
      padding: 0 0.5rem;
      i {
        margin-right: 0.5rem;
      }
      .cutlery-icon {
        height: 15px;
        width: 15px;
        margin-right: 0.5rem;
      }
    }
    .user-login,
    .cart-logo,
    .language-selector {
      padding: 0 0.5rem;
    }
    .cart-logo {
      position: relative;
      font-size: 1.3rem;
      color: #fff;
      margin-right: 1rem;
      .badge-pill {
        padding: 5px 8px;
        border-radius: 1rem;
        position: absolute;
        top: -8px;
        right: -18px;
        z-index: 9;
      }
    }
    #language-selector {
      font-size: 1.3rem;
      margin: 0 0 0 1rem;
      &:after {
        content: "";
        height: 2.2rem;
        width: 0.1rem;
        background-color: lightgrey;
        position: absolute;
        right: 45px;
        @media (max-width: 2560px) {
          right: 4.2rem;
        }
        @media (max-width: 1096px) {
          right: 2.9rem;
        }
        @media (max-width: 991px) {
          right: 1.9rem;
        }
      }
    }
    @media (max-width: 1096px) {
      font-size: 0.9rem;
      margin-right: 0;
    }
    @media (max-width: 574px) {
      margin-top: 0.2rem;
    }
    @media (max-width: 519px) {
      .store-locator {
        display: none;
      }
      .cart-logo,
      #language-selector {
        i {
          font-size: 18px;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .logo {
      padding: 0;
    }
  }
  @media (max-width: 991px) {
    height: 11rem;
    position: relative;
    .search-bar {
      position: absolute;
      top: -40px;
      left: 1px;
      max-width: 90%;
    }
    .icons-shortcut {
      float: right;
      position: absolute;
      top: -150px;
      right: 2rem;
    }
  }
  @media (max-width: 574px) {
    height: 10rem;
    .search-bar {
      max-width: 85%;
      .search-icon-box {
        height: 2.5rem;
        #basic-addon1 {
          i {
            font-size: 1rem;
          }
        }
      }
      .input-search-box {
        height: 2.5rem;
        font-size: 14px;
        &::placeholder {
          font-size: 14px;
        }
      }
    }
    .icons-shortcut {
      top: -140px;
    }
  }
  @media (max-width: 374px) {
    .logo {
      max-width: 40%;
    }
    .icons-shortcut {
      right: 15px;
    }
  }
}
// Navbar
.navbar {
  .nav-item {
    .nav-link,
    .dropdown-item {
      font-size: 14px;
      color: #000000;
    }
  }
  #category-dropdown-item {
    &:after {
      content: "";
      height: 1.6rem;
      width: 1px;
      background-color: darkgrey;
      position: absolute;
      right: 0px;
      top: 7px;
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
  .app-link {
    @media (min-width: 991px) {
      border-left: 2px solid darkgray;
    }
  }
  @media (max-width: 1117px) and (min-width: 992px) {
    .nav-item {
      .nav-link,
      .dropdown-item {
        font-size: 12px;
      }
    }
  }
  @media (max-width: 991px) {
    .navbar-toggler {
      position: absolute;
      top: -68px;
      right: 0;
      i {
        color: #fff;
        font-size: 34px;
      }
      &:focus {
        outline: none;
      }
    }
  }
  @media (max-width: 574px) {
    .navbar-toggler {
      position: absolute;
      top: -60px;
      right: 0;
      i {
        color: #fff;
        font-size: 30px;
      }
      &:focus {
        outline: none;
      }
    }
  }
  @media (max-width: 330px) {
    .navbar-toggler {
      top: -70px;
    }
  }
}

// .loginModalInnerDiv {
//   display: flex;
//   padding: 0px;
//   margin: 0px;
//   .modalLeftDiv {
//     width: 100%;
//     height: auto;
//     background-color: green;
//   }
//   .modalRightDiv {
//     width: 100%;
//     height: auto;
//     background-color: red;
//   }
// }

.loginModalInnerDiv {
  display: flex;
  padding: 0px;
  margin: 0px;
  justify-content: center;
  align-items: center;
  .modalLeftDiv {
    width: 50%;
    // height: 500px;
    height: 100%;
    // background-color: yellowgreen;
    .closeButtonSignUp {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -10px;
      margin-right: -10px;
      cursor: pointer;
      // margin-top: -20px;
      // margin-right: -410px;
      background-color: white;
      border-radius: 50px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px $primary solid;
      z-index: 9999;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .modalRightDiv {
    width: 50%;
    // height: 500px;
    height: 100%;
    // background-color: orange;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .closeButton {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -10px;
      margin-right: -10px;
      cursor: pointer;
      // margin-top: -65px;
      // margin-right: -15px;
      background-color: white;
      border-radius: 50px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px $primary solid;
    }
    .socialMediaButton {
      width: 100%;
      margin: 10px 0px;
      // height: 30%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    .loginWithSocialMediaDiv {
      width: 90%;
      // height: 200px;
      // background-color: red;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 10px;
      .loginTitle {
        margin: 10px;
        width: 100%;
        // height: 30%;
        // background-color: blue;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .inputFieldForLogin {
      width: 90%;
      height: auto;
      // background-color: grey;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin-bottom: 20px;
      .inputGroup {
        label {
          font-weight: 600;
          letter-spacing: 0.5px;
        }
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .loginModalInnerDiv {
    flex-direction: column;
    .modalLeftDiv {
      display: none;
      .closeButtonSignUp {
        display: none;
      }
    }
    .modalRightDiv {
      width: auto;
      height: auto;
      // background-color: maroon;
      width: 100%;
      height: 100%;
      .closeButton {
        display: none;
      }
      .loginTitle {
        span {
          font-size: 20px !important;
        }
      }
    }
  }
}

.errMsg {
  font-size: 12px;
}

.forgetPasswordButton {
  width: 250px;
  height: 40px;
  // background-color: #ffbd1b;
  background-color: yellowgreen;
  outline: "none";
  border-width: 0ch;
  color: white;
  span {
    font-size: 17px;
    font-weight: bold;
  }
}
.forgetPasswordEmailField {
  outline: "none";
  width: 250px;
  height: 35px;
}

.languageSelectorDiv {
  margin-left: 10px;
  margin-right: 10px;
  // background-color: orange;
  border-left: 2px #d3d3d3 solid;
  padding-left: 5px;
  position: relative;
  .badge-pill {
    // padding: 5px 9px;
    // border-radius: 1rem;
    // left: 8px;
    // top: 16px;
    font-size: 10px;
    // position: absolute;
    z-index: 9;
  }
}

@media only screen and (max-width: 600px) {
  .languageSelectorDiv {
    display: none;
  }
}

@media (max-width: 1313px) and (min-width: 992px) {
  .loginRightDivMainDiv {
    // background-color: red;
    .loginAndCartMainDiv {
      // background-color: yellow;
      // margin: 0px !important;
      .store-locator {
        display: none;
      }
    }
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .loginRightDivMainDiv {
    min-width: 100% !important;
  }
}

.labelBorder {
  border-left: 1px $buyBg dashed !important;
  border-top: 1px $buyBg dashed !important;
  border-right: 1px $buyBg dashed !important;
}
.inputFieldBorder {
  border-left: 1px $buyBg dashed !important;
  border-right: 1px $buyBg dashed !important;
}

@media (max-width: 992px) {
  .top-header {
    // height: auto;
    height: 150px !important;
    // .logo {
    // height: auto !important;
    // height: 75px !important;
    // }
    .loginRightDivMainDiv {
      height: auto !important;
      // background-color: yellow;
      .loginAndCartMainDiv {
        height: auto !important;
        // background-color: red;
        margin-top: 10px;
      }
    }
    .search-bar {
      padding: 1.5rem 0.5rem;
    }
  }
}

// on Scroll Heigh Change State
// .top-headerOnScroll {
//   height: 60px !important;
//   background-color: $primary;
//   .logo {
//     padding: 0 2rem 0 0.5rem;
//     margin: 0.7rem 0;
//     height: 40px;
//   }
//   .loginRightDivMainDiv {
//     height: 40px !important;
//     .loginAndCartMainDiv {
//       height: 30px !important;
//       // background-color: red;
//       padding-top: 20px;
//     }
//   }
//   .search-bar {
//     // padding: 1.5rem 0.5rem;
//     padding-top: 5px;
//     height: 20px !important;
//     .search-icon-box {
//       #basic-addon1 {
//         border-top-left-radius: 40%;
//         border-bottom-left-radius: 40%;
//         border-color: #fff;
//         i {
//           font-size: 1.3rem;
//           color: lightgrey;
//         }
//         &:after {
//           content: "";
//           margin-left: 0.8rem;
//           height: 2.2rem;
//           width: 0.1rem;
//           background-color: lightgrey;
//         }
//       }
//     }
//     .input-search-box {
//       height: 3.2rem;
//       border: none;
//       margin-top: 0;
//       box-shadow: none;
//       border-radius: 0;
//       padding-bottom: 10px;
//       &:focus {
//         box-shadow: none;
//       }
//       &::placeholder {
//         color: lightgrey;
//       }
//     }
//     .clear-icon-box {
//       #basic-addon2 {
//         border-color: #fff;
//         border-top-right-radius: 50%;
//         border-bottom-right-radius: 50%;
//         i {
//           margin-right: 5px;
//           color: $dealsBg2;
//           font-size: 1.3rem;
//         }
//       }
//     }
//     .search-bar-content {
//       display: none;
//       height: 200px;
//       overflow-y: scroll;
//       max-width: 97.5%;
//       position: absolute;
//       top: 75px;
//       z-index: 99999;
//       .search-bar-message {
//         // margin-top: 15%;
//         vertical-align: middle;
//         text-align: center;
//       }
//       ul {
//         list-style: none;
//         padding: 1rem;
//         li {
//           padding: 1rem 0;
//           a {
//             text-decoration: none;
//             color: #000;
//           }
//         }
//       }
//       @media (max-width: 2560px) {
//         max-width: 98.6%;
//       }
//       @media (max-width: 1900px) {
//         max-width: 98.3%;
//       }
//       @media (max-width: 1440px) {
//         max-width: 97.7%;
//       }
//       @media (max-width: 1199px) {
//         max-width: 96.5%;
//       }
//       @media (max-width: 991px) {
//         max-width: 97.5%;
//       }
//       @media (max-width: 574px) {
//         max-width: 96.5%;
//         top: 65px;
//       }
//       @media (max-width: 426px) {
//         max-width: 95.5%;
//       }
//     }
//   }
//   .icons-shortcut {
//     color: #fff;
//     text-transform: uppercase;
//     padding: 1.8rem 0;
//     margin-right: 20px;
//     font-size: 0.99rem;
//     font-weight: 300;
//     font-family: "Montserret", sans-serif;
//     .store-locator {
//       cursor: pointer;
//       padding: 0 0.5rem;
//       i {
//         margin-right: 0.5rem;
//       }
//     }
//     .user-login,
//     .cart-logo,
//     .language-selector {
//       padding: 0 0.5rem;
//     }
//     .cart-logo {
//       font-size: 1.3rem;
//       color: #fff;
//       .badge-pill {
//         padding: 5px 9px;
//         border-radius: 1rem;
//         position: absolute;
//         top: 20px;
//         z-index: 9;
//       }
//     }
//     #language-selector {
//       font-size: 1.3rem;
//       margin: 0 0 0 1rem;
//       &:after {
//         content: "";
//         height: 2.2rem;
//         width: 0.1rem;
//         background-color: lightgrey;
//         position: absolute;
//         right: 45px;
//         @media (max-width: 2560px) {
//           right: 4.2rem;
//         }
//         @media (max-width: 1096px) {
//           right: 2.9rem;
//         }
//         @media (max-width: 991px) {
//           right: 1.9rem;
//         }
//       }
//     }
//     @media (max-width: 1096px) {
//       font-size: 0.9rem;
//       margin-right: 0;
//     }
//     @media (max-width: 574px) {
//       margin-top: 0.2rem;
//     }
//     @media (max-width: 519px) {
//       .store-locator {
//         display: none;
//       }
//       .cart-logo,
//       #language-selector {
//         i {
//           font-size: 18px;
//         }
//       }
//     }
//   }
//   @media (max-width: 1200px) {
//     .logo {
//       padding: 0;
//     }
//   }
//   @media (max-width: 991px) {
//     height: 11rem;
//     position: relative;
//     .search-bar {
//       position: absolute;
//       top: -40px;
//       left: 1px;
//       max-width: 90%;
//     }
//     .icons-shortcut {
//       float: right;
//       position: absolute;
//       top: -150px;
//       right: 2rem;
//     }
//   }
//   @media (max-width: 574px) {
//     height: 10rem;
//     .search-bar {
//       max-width: 85%;
//       .search-icon-box {
//         height: 2.5rem;
//         #basic-addon1 {
//           i {
//             font-size: 1rem;
//           }
//         }
//       }
//       .input-search-box {
//         height: 2.5rem;
//         font-size: 14px;
//         &::placeholder {
//           font-size: 14px;
//         }
//       }
//     }
//     .icons-shortcut {
//       top: -140px;
//     }
//   }
//   @media (max-width: 374px) {
//     .logo {
//       max-width: 40%;
//     }
//     .icons-shortcut {
//       right: 15px;
//     }
//   }
// }
// on Scroll Heigh Change State Ends

.forgotPassowrdcloseButton {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -10px;
  margin-right: -10px;
  cursor: pointer;
  // margin-top: -20px;
  // margin-right: -410px;
  background-color: white;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px $primary solid;
  z-index: 9999;
}
