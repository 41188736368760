@import "variable";

.userAvatarMainDiv {
  background-color: $buyBg;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  .userImageDiv {
    width: 200px;
    height: 200px;
    border-radius: 200px;
    overflow: hidden;
    border: 2px solid #fff;
    img {
      height: 200px;
      max-width: 200px;
    }
    .image-upload-btn {
      text-align: center;
      background-color: rgba(0,0,0,0.5);
      z-index: 9999;
      position: relative;
      margin-top: -30px;
      color: #fff;
      padding: 1rem;
      i {
        position: absolute;
        top: 7px;
        left: 45%;
      }
    }
  }
  .userName {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .userAvatarMainDiv {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
}
